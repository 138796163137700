<template>
  <div>
    <b-button
      :id="id_button"
      size="sm"
      variant="primary"
      :title="text_button"
      v-b-tooltip.topleft.v-secondary.noninteractive="{
        title: tooltip_text ? tooltip_text : '',
        boundary: 'viewport',
      }"
      @click="handleClick"
      class="noprint visible-columns"
      :class="custom_class"
      :disabled="disabled_button"
    >
      <b-icon-gear v-if="show_icon"></b-icon-gear>
      <span v-if="text_button" class="ml-1">
        {{ text_button }}
      </span>
    </b-button>
    <b-popover
      :id="`popover-${id_button}`"
      :target="id_button"
      placement="bottom"
      triggers="click blur"
      boundary="viewport"
    >
      <template #title>
        <slot name="popover-title" />
      </template>
      <slot name="popover-content" />
    </b-popover>
  </div>
</template>

<script>
export default {
  name: "VisibleColumns",
  props: {
    id_button: {
      type: String,
      default: "",
    },
    text_button: {
      type: String,
      default: "",
    },
    tooltip_text: {
      type: String,
      required: true,
    },
    click_button: {
      type: Function,
      required: false,
    },
    size: {
      type: String,
      default: "sm",
    },
    // variant: {
    //   type: String,
    //   default: "secondary",
    // },
    show_icon: {
      type: Boolean,
      default: true,
    },
    custom_class: {
      type: String,
      default: "",
    },
    disabled_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleClick() {
      // Si se proporciona una función de click, se ejecuta
      if (this.click_button) {
        this.click_button();
      } else {
        this.$emit("click");
      }
    },
  },
};
</script>

<style scoped>
/* .visible-columns {
  padding: 2px !important;
} */
</style>